import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { Conversation } from "@app/shared/models/entities/ai-teacher/Conversation";
import { ModelService } from "@app/shared/services/model.service";
import { HttpEventStreamService } from "@app/shared/services/utility-services/http-event-stream.service";
import { ProductLine } from "@app/shops/models/Product";

export interface CreateConversationBody {
  question: string;
  course?: number;
  facade_group?: number;
  training?: number;
  product_line?: ProductLine;
  exam_attempt?: number;
  additional_context?: string;
  url?: string;
  has_requested_employee?: boolean;
}

export interface UpdateConversationBody extends Partial<Pick<Conversation, "title" | "status">> {
  has_requested_employee?: boolean;
}

export interface CreateMessageBody {
  question: string;
  course?: number;
  training?: number;
  product_line?: ProductLine;
  exam_attempt?: number;
  additional_context?: string;
}

@Injectable({
  providedIn: "root",
})
export class AITeacherService {
  private readonly aiTeachersUrl: string = environment.apiEndpoint + "/ai-teachers";
  private readonly conversationsUrl: string = this.aiTeachersUrl + "/conversations";

  constructor(
    private readonly modelService: ModelService,
    private readonly httpStreamService: HttpEventStreamService
  ) {}

  getConversations(): Observable<Conversation[]> {
    return this.modelService.getModels(this.conversationsUrl, Conversation);
  }

  getConversation(id: number): Observable<Conversation> {
    return this.modelService.getModel(`${this.conversationsUrl}/${id}`, Conversation);
  }

  createConversation(body: CreateConversationBody): Observable<string> {
    return this.httpStreamService.post(this.conversationsUrl, body);
  }

  updateConversation(id: number, body: UpdateConversationBody): Observable<Conversation> {
    return this.modelService.patchModel(`${this.conversationsUrl}/${id}`, Conversation, body);
  }

  createMessage(id: number, body: CreateMessageBody): Observable<string> {
    return this.httpStreamService.post(`${this.conversationsUrl}/${id}/messages`, body);
  }
}
