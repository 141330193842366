import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { ChatMessageComponent } from "@app/ai-teacher/components/chat-message/chat-message.component";
import { ButtonComponent, LoadingDotsBlueComponent } from "@app/shared/basic-components";
import { Message } from "@app/shared/models/entities/ai-teacher/Message";
import { AITeacherService } from "@app/shared/services/ai-teacher.service";

@Component({
  selector: "chat-message-list",
  standalone: true,
  templateUrl: "./chat-message-list.component.html",
  styleUrl: "./chat-message-list.component.scss",
  imports: [ChatMessageComponent, LoadingDotsBlueComponent, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageListComponent implements AfterViewChecked {
  @Input() protected messages: Message[] = [];
  @Input() private selectedConversationId!: number;
  @Input() protected showSupportSection = false;

  @ViewChild("container") private readonly container?: ElementRef<HTMLDivElement>;

  constructor(private aiTeacherService: AITeacherService) {}

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  // TODO: Temporarily hidden until the AI Teacher can handle a helpful response
  // protected onHelpfulResponseClick() {
  //   this.showFeedbackButtons = false;
  // }

  protected onContactEmployeeClick() {
    this.aiTeacherService
      .updateConversation(this.selectedConversationId, {
        has_requested_employee: true,
      })
      .subscribe(() => {
        window.open("contact", "_blank");
      });
  }

  private scrollToBottom() {
    this.container?.nativeElement.scrollTo({
      top: this.container.nativeElement.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }
}
